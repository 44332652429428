.page-banner {
	
	position: relative;
	overflow-y: hidden;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	
	& > .bg-image {
		z-index: 1;
		position: absolute;
			top: 0;
			left: 0;
		min-height: 100%;
		min-width: 100%;
	}
	
	& > .content {
		
		@extend .grid-x;
		position: relative;
		z-index: 2;
		
	}
	
	// Alternatives
	&.-hero {
		
		& > .content {
			
			@extend .align-center;
			@extend .align-middle;
			min-height: 75vh;
			text-align: center;
			
		}
		
	}
	
	&.-split {
		
		overflow: visible;
		
		& > .content {	
			@extend .align-middle;
			min-height: 75vh;
		}
		
		& > .bg-image {
			z-index: 1;
			position: absolute;
				top: auto;
				left: auto;
				bottom: -4rem;
				right: 0;
			min-width: 0px;
			min-height: 0px;
			max-width: 40%;
			max-height: calc(100% - 4rem - 120px);
		}
		
	}
	
}

.social-media a {
	margin-right: 1rem;
	font-size: 1.3rem;
}