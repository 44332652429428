/*
	
	
	Link Styles
	
	
*/

.link {
	@include font(
		$font: 'CourierNew',
		$size: 1rem,
	);
	text-transform: uppercase;
	position: relative;
	padding-bottom: .3rem;
	
	&:after {
		display: block;
		content: "";
		position: absolute;	
			bottom: 0;
			left: 0;
		width: 100%;
		height: 1px;
		background: #000;
	}
	
	&:hover {
		opacity: .65;
	}
	
	&.-header,
	&.-menu {
		font-family: 'AndaleMono';
		font-size: 1.1rem;
		padding-bottom: 0rem;
		
		&:after {
			display: none;
		}
	}
	&.-menu {
		display: block;
		text-align: left;
		font-size: 1.3rem;
		margin-bottom: 2rem;
	}
	&.-footer {
		font-family: 'AndaleMono';
		font-size: 1.3rem;
		color: #fff;
		padding-bottom: 0rem;
		display: inline-block;
		margin-bottom: .5rem;
		
		&:after {
			display: none;
		}
		&:hover,
		&:focus {
			color: #fff;
		}
	}
	&.-large {
		font-size: 1.2rem;
	}
	
	&.-color {
		@each $name, $color in $colors {
			&-#{$name} {
				
				&:after {
					background: $color;
				}
				
				&:hover,
				&:focus {
					color: $color;
				}
				
			}
		}
	}
}


@media only screen and (max-width: 640px) {
	
	.page-footer {
		
		position: relative;
		
		& > .logo {
			
			position: absolute;
				top: 10rem;
				right: 12vw;
			width: 40% !important;
			transform: rotate(23deg);
			
		}
		
	}
	
}


@media only screen and (min-width: 640px) {
	
	
	.link {
		&.-footer {
			margin-bottom: 0rem;
		}
	}
	
	
}


/*
	
	
	Input styles
	
	
*/


// Base input styles
input[type] {
	
	// Resets
	width: 100%;
	appearance: none;
	box-shadow: none;
	outline: none;
	border: none;
	background: transparent;
	
	// Styling
	@extend .copy-one;
	font-family: "WorkSans" !important;
	font-size: 1.2rem;
	border-bottom: 1px solid #000;
	padding: .3rem 0px;
	transition: all .3s ease-out;
	
	&::Placeholder {
		transition: all .3s ease-out;
	}
	
	&:hover {
		
		color: #000;
		
		&::Placeholder {
			color: #000;
		}
		
	}
	
}

// Radio input styles
.input-container {

	
}

.input-label {
	@extend .copy-one;
	font-family: "WorkSans" !important;
	font-size: 1.2rem !important;
	color: $medium-grey !important;
	margin-left: 0rem;
	position: relative;
	transition: all .3s ease-out;
	padding-left: 25px;
	
	&:before {
		display: block;
		content: "";
		position: absolute;
			top: 50%;
			left: 0;
		width: 10px;
		height: 10px;
		border-radius: 100px;
		border: 1px solid $medium-grey;
		transform: translateY(-50%);
		transition: all .3s ease-out;
	}
	
	&:hover {
		
		color: #000 !important;
		&:before {
			border-color: #000;
		}
		
	}
}

.input-radio {
	display: none;
	
	&:checked + .input-label {
		color: #000 !important;
		
		&:before {
			border-color: #000;
			background: #000;
		}
	}
}

@media only screen and (min-width: 640px) {
	
	.input-label {
		margin-left: 2rem;
	}
	
}