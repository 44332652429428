.box-grid {
	
	@extend .grid-x;
	margin: 0rem -1.5rem;
	
	& > .box-card {
		@extend .cell;
		margin: 0rem 1.5rem;
		width: calc(100% - 3rem);
		
		&.-square {
			padding-bottom: calc(100% - 3rem);
		}
	}
	
}


.box-card {
	
	position: relative;
	overflow: hidden;
	margin-bottom: 2rem !important;
	
	& > .bg-image {
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		max-width: 150%;
		max-height: 150%;
	}
	
}

@media only screen and (max-width: 640px) {
	
	.box-grid {
		
		&.-sm-two-up {
			
			& > .box-card {
				width: calc(50% - 3rem);
				
				&.-square {
					padding-bottom: calc(50% - 3rem);
				}
			}
			
		}
		
	}
	
}

@media only screen and (min-width: 640px) {
	
	.box-grid {
		
		
		&.-four-up {
			
			& > .box-card {
				width: calc(25% - 3rem);
				
				&.-square {
					padding-bottom: calc(25% - 3rem);
				}
			}
			
		}
		
		&.-two-up {
			
			& > .box-card {
				width: calc(50% - 3rem);
				
				&.-square {
					padding-bottom: calc(50% - 3rem);
				}
			}
			
		}
		
	}
	
	.box-card {
		margin-bottom: 0rem;
	}
	
}