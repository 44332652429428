.flex-layout {
	
	@extend .grid-x;
	
	&.-fixed-scroll {
		@extend .align-stretch;
	}
	
	&.-bg-texture {
		position: relative;
		overflow: hidden;
		
		& > .bg-texture {
			z-index: -1;
			position: absolute;
				top: 2rem;
				left: 50%;
			width: 120%;
			max-height: 100%;
			max-width: none;
			transform: rotate(-6deg) translateX(-50%);
			
			&.-home {
				width: 120%;
				height: 90vh;
				top: 20%;
				opacity: .5;
			}
		}
	}
	
	&.-bg-image {
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}
}

.image-stack {
	
	& > img {
		margin-bottom: 2rem;
	}
	
}