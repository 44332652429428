// Body
$global-max-width: rem-calc(1200);
$global-x-gutter: 12vw;
$global-y-gutter: 5rem;

// Fonts
$global-font-size: 100%;
$global-lineheight: 1.5;
$global-weight-normal: normal;
$global-weight-bold: bold;



// Colors
$light-grey: #f8f8f8;
$medium-grey: #a8a8a8;
$black: #020203;
$white: #fff;
$green: #7A9F76;

$colors: (
  lightGrey: $light-grey,
	mediumGrey: $medium-grey,
	black: $black,
	white: $white,
	green: $green
);



//
// Easings
//
$in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);