.table-container {
	
	position: relative;
	padding: 8rem 10vw;
	overflow: hidden;
	
	& > .bg-texture {
		z-index: 1;
		position: absolute;
			top: 50%;
			left: 50%;
		transform: translate(-50%, -50%);
		width: 110%;
		max-width: none;
	}
	
	& > .table {
		position: relative;
		z-index: 2;
	}
	
}


.hours-table {
	
	border-collapse: collapse;
	margin: 0 auto;
	display: none;
	
	&.-mobile {
		display: table;
		width: 100%;
	}
	
	th {
		@extend .header-two;
		color: $green;
		line-height: 1.7em;
		width: 1.7em;
		border: 2px solid $white;
	}
	
	td {
		@extend .header-four;
		color: $white;
		border: 2px solid $white;
		border-bottom: 0px solid transparent;
		padding: 2rem 1rem;
		text-align: left;
		text-transform: none;
		
		&.day {
			font-size: 1.5rem;
			color: $green;
			line-height: 1.7em;
			width: 1.7em;
			border: 2px solid $white;
		}
		&.hours {
			font-size: 1.4rem;
			border-bottom: 2px solid $white;
		}
	}
}

@media only screen and (min-width: 640px) {
	
	.table-container {
		padding: 12rem 0px;
	}
	
	.hours-table {
		display: table;
	}
	
}