// Steradian

@font-face {
  font-family: 'Cinzel';
  src: url('../fonts/Cinzel-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'AndaleMono';
  src: url('../fonts/AndaleMono.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'CourierNew';
  src: url('../fonts/CourierNewBold.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans-Regular.ttf');
  font-style: normal;
}


/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "AndaleMono",
		$color: #000
		
		)
*/

h1,
h2,
h3,
h4,
h5,
h6 {
	@include font(
		$weight: 400,
	);
	margin-bottom: 0px;
}

p {
	
	@include font(
		$font: 'Helvetica-Neue',
		$line-height: 1.8em
	);
	letter-spacing: .02em;
	margin-bottom: 0px;
}