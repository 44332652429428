/*
		font(
			
		$style: normal,
		$variant: normal,
		$weight: 400,
		$size: 1em,
		$line-height: 1.2em,
		$font: "Helvetica-Neue",
		$color: #000
		
		)
*/


//
//
//	------
//	Headers
//	------
//
//

.header-one,
h1 {
	@include font(
		
		$font: 'Cinzel',
		$size: 3.2rem,
		$line-height: 1.3em
		
	);
	text-transform: uppercase;
}

.header-two,
h2 {
	@include font(
		
		$size: 3.2rem,
		$line-height: 1.2em,
		$color: $green
		
	);
	text-transform: uppercase;
}

.header-three,
h3 {
	@include font(
		
		$size: 2.375rem,
		$line-height: 1.3em
		
	);
	text-transform: uppercase;
}

.header-four,
h4 {
	@include font(
		
		$size: 1.563rem,
		$line-height: 1.5em
		
	);
	text-transform: uppercase;
}

.header-five,
h5 {
	@include font(
		
		$size: 1.2rem,
		$line-height: 1.5em
		
	);
	text-transform: uppercase;
}




//
//
//	------
//	Copy
//	------
//
//

.copy-one,
p {
	@include font(
		
		$size: 1rem,
		$line-height: 1.5em,
		
	);
}

@media only screen and (min-width: 640px) {
	
	.header-one, h1 {
		
		font-size: 4rem;
		
	}
	
	.copy-one,
	p {
		font-size: 1.1rem;
	}
	
}




//
//
// -----
// Helpers
// -----
//
//

.-color {
	@each $name, $color in $colors {
		&-#{$name} {
			color: $color;
			
			& > p {
				color: $color;
			}
		}
	}
}

.-workSans {
	font-family: 'WorkSans';
	text-transform: none;
}

.-courier {
	font-family: 'CourierNew';
}

.-lowercase {
	text-transform: none;
}

@media only screen and (min-width: 640px) {
	
	.-two-columns {
		
		column-count: 2;
		column-gap: 2rem;
		
	}
	
}