.page-footer {
	
	@extend .grid-x;
	padding: 7rem 12vw;
	background: $black;
	
}

.footer-nav {
	
	@extend .grid-x;
	border-bottom: 2px solid #fff;
	margin-left: 5vw;
	
	.copy-one {
		font-size: 1rem;
	}
	
	.grid-x {
		width: 100%;
		padding-bottom: 1rem;
	}
}

.footer-copyright {
	margin-left: 5vw;
	
	.header-four {
		font-size: 1rem;
	}
}

a.header-five {
	
	@extend .link.-footer;
	font-size: 1rem;
	
	&:hover {
		opacity: .65;
	}
	
}