.contact-form-container {
	
	@extend .grid-x;
	
	position: relative;
	padding: 10rem 12vw;
	background-size: cover;
	background-position: center center;
	
	& > .icon {
		
		position: absolute;
			top: 3rem;
			right: -10vw;
		width: 35vw;
		transform: rotate(5deg);
		
	}
	
}

.contact-form {
	
	& > input[type] {
		
		margin-bottom: 1.5rem;
		font-size: 1.2rem !important;
		
	}	

}


@media only screen and (min-width: 640px) {
	
	
	.contact-form-container {
		
		& > .icon {
			
			position: absolute;
				top: -3rem;
				right: -10px;
			width: 15vw;
			transform: rotate(5deg);
			
		}
		
	}
	
	
}