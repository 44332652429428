// Interactive

a {
	@include font();
	transition: all .3s ease-out;
}

// Layout

section {
	padding: $global-y-gutter $global-x-gutter;
}

// Helpers

.-bg {
	@each $name, $color in $colors {
		&-#{$name} {
			background: $color;
		}
	}
}