.crest-pos {
	
	position: relative;
	overflow: visible !important;
	
	& > .crest {
		position: absolute;
			top: 0;
			left: 0;
		width: 30%;
		transform: translate(-30%, -40%) rotate(-20deg);
	}
	
}

.crest-story {
	position: absolute;
		top: 0rem;
		left: -8vw;
	transform: rotate(-29deg);
	width: 40%;
	z-index: 2;
}

.team-grid {
	
	@extend .grid-x;
	padding: 8rem 12vw;
	margin: 0 -4vw;
	background: $green;
	position: relative;
	overflow: hidden;
	
	& > .icon {
		position: absolute;
			bottom: 0;
			right: 0;
		transform: translate(5%, 20%) rotate(10deg);
		width: 20vw;
	}
	
}

.team-member {
	
	@extend .cell;
	width: calc(100% - 8vw);
	margin: 0 4vw;
	margin-bottom: 4rem;
	
	& > .headshot {
		width: 100%;
		padding-bottom: 100%;
		background: $white;
		margin-bottom: 2.5rem;
		background-size: cover;
		background-position: center center;
	}
	
	& > .header-three {
		margin-bottom: 1.5rem;
	}
	
}

@media only screen and (min-width: 640px) {
	
	.team-member {
		width: calc(50% - 8vw);
	}
	
	.team-grid {
		
		padding: 8rem 20vw;
		
	}
	
	.crest-story {
		width: 150px;
			top: 1.5rem;
			left: -30px;
	}
	
}