// Biggest container
.page-header {
	
	position: relative;
	z-index: 2;
	
	&.-white {
		color: $white;
	}
	
	& > .floating-container {
		@extend .grid-x;
		@extend .align-center;
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		padding: 1.5rem $global-x-gutter;
	}
	
}

// Main nav container
.nav-container {
	
	@extend .grid-x;
	@extend .align-middle;
	justify-content: space-between;
	max-width: $global-max-width;
	position: relative;
	
}

.nav-hamburger {
	
	@extend .cell;
	width: 50%;
	text-align: right;
	
	& > .hamburger-black {
		display: inline-block;
	}
	& > .hamburger-white {
		display: none;
	}
	& > .cross {
		min-width: 25px;
	}
	
	&.-white {
		
		& > .hamburger-black {
			display: none;
		}
		& > .hamburger-white {
			display: inline-block;
		}
		
	}
	
}


.logo-container {
	
	@extend .cell;
	width: 50%;
	
	& > .logo-black {
		display: block;
	}
	& > .logo-white {
		display: none;
	}
	
	&.-white {
		
		& > .logo-black {
			display: none;
		}
		& > .logo-white {
			display: block;
		}
		
	}
	
}

// Side Containers
.nav-cell {
	
	@extend .small-12;
	@extend .medium-6;
	@extend .cell;
	display: none;
	
	&.-left {
		padding-right: 12vw;
		
		& > .inner {
			@extend .align-right;
		}
		.cell {
			margin-right: 5rem;
		}
	}
	&.-right {
		padding-left: 12vw;
		& > .inner {
			@extend .align-left;
		}
		.cell {
			margin-left: 5rem;
		}
	}
	
	
	& > .inner {
		height: 100%;
		@extend .grid-x;
		@extend .align-middle;
	}
}

.page-menu {
	z-index: 500;
	position: fixed;
		top: 0;
		left: 0;
	width: 100%;
	height: 100%;
	margin: 0px !important;
	padding: 0px;
	transform: translateY(100%);
	transition: all 0s linear 1.1s;
	
	&:after {
		z-index: 2;
		display: block;
		content: "";
		position: absolute;
			bottom: 0;
			left: 0;
		width: 100%;
		height: 100%;
		background: $green;
		transform: translateY(120%);
		transform-origin: bottom;
		transition: all .8s ease-out .1s;
	}
	
	&.-open {
		transform: translateY(0%);
		transition: all 0s linear 0s;
		
		& > .menu-inner {
			transform: translateY(0%);
			transition: all .8s ease-out .1s !important;
		}
		&:after {
			transform: translateY(0%);
			transition: all .8s ease-out 0s !important;
		}
	}
}

.menu-inner {
	
	@extend .grid-x;

	z-index: 3;
	width: 100%;
	height: 100%;
	position: relative;
	margin: 0px !important;
	padding: 3rem $global-x-gutter;
	background-image: url('../brand/Bg_Texture_Gray.png');
	background-size: cover;
	background-position: center center;
	transform: translateY(120%);
	transition: all .8s ease-out 0s;
	
	& > .icon {
		position: absolute;
			bottom: 10vh;
			right: 12vw;
		width: 32vw;
		transform: rotate(-12deg);
	}
	& > .typebadge {
		position: absolute;
			top: 1.5rem;
			right: 12vw;
		width: 20vw;
	}
}

.page-menu-header {
	@extend .grid-x;
	@extend .cell;
	width: 100%;
	height: auto;
	
	& > .nav-hamburger {
		width: 100%;
		text-align: left;
	}
}

.menu-nav {
	@extend .cell;
	width: 100%;
	text-align: left;
}

.menu-hours {
	@extend .cell;
	width: 100%;
}

@media only screen and (min-width: 640px) {
	
	.logo-container {
		
		width: 30%;
		max-width: 200px;
		
	}
	
	.nav-hamburger {
		
		width: auto;
		
	}
	
}

@media only screen and (min-width: 1024px) {
	
	.page-header {
		& > .floating-container {
			padding: 3rem $global-x-gutter;
		}
	}
	.nav-container {
		justify-content: center;
	}
	.nav-cell {
		display: block;
	}
	.nav-hamburger {
		display: none;
	}
	.logo-container {
		position: absolute;
			left: 50%;
			top: 50%;
		transform: translate(-50%, -50%);
		width: 16vw;
		max-width: none;
	}
	
}