.menu-section {
	
	position: relative;
	padding: 5rem 0vw;
	
	& > .icon {
		position: absolute;
		width: 10vw;
		min-width: 70px;
		
		&.-top {
			top: 0;
		}
		&.-right {
			right: 5vw;
		}
		&.-left {
			left: 5vw;
		}
		&.-bottom {
			bottom: 0;
		}
	}
	
}

.menu-header {
	text-align: center !important;
	margin-bottom: 3rem;
	color: $black;
	padding: 0rem 10vw;
}

.menu-table-nav {
	text-align: center;
	margin-bottom: 3rem;
	margin-left: -12vw;
	margin-right: -12vw;
	display: block !important;
	width: calc(100% + 24vw);
	padding: 0rem 10vw;
	
	& > .link {
		color: $medium-grey;
		margin: 0 .5rem;
		transition: all .3s $easeOutCubic;
		cursor: pointer;
		display: inline-block;
		margin-bottom: 1rem;
		
		&:after {
			background: $medium-grey;
			opacity: 0;
			transform: translateY(5px);
			transition: all .3s $easeOutCubic;
		}
		
		&:hover,
		&.-active {
			&:after {
				opacity: 1;
				transform: translateY(0px);
			}
		}
		
		&.-active {
			color: $black;
			
			&:after {
				background: $black;
			}
		}
	}
}


.menu-tables-container {
	@extend .grid-x;
	margin: 0rem -12vw;
	flex-wrap: nowrap;
	transition: all .6s $easeOutCubic;
}

.menu-table {
	
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	border-collapse: collapse;
	
	.expand {
		width: 99%;
		padding: .8rem 1rem;
		@include font(
			$size: 1rem,
			$line-height: 1.5em,
		);
	}
	.shrink {
		white-space: nowrap;
		text-align: center;
		padding: .8rem 1.5rem;
		@include font(
			$size: 1rem,
			$line-height: 1.5em,	
		);
		
		&.-med {
			border-right: 1px solid #000;
			border-left: 1px solid #000;
		}
	}
	
	.item {
		background: $light-grey;
		
		&.-offset {
			background: #fff;
		}
	}
	
	.header-five {
		font-weight: 700;
		margin-bottom: 0px;
	}
	.copy-one {
		font-size: .8rem;
		line-height: 1.2em;
	}
	
}


@media only screen and (min-width: 640px) {
	
	.menu-section {
		padding: 6rem 12vw;
	}
	
	.menu-tables-container {
		margin: 0px;
	}
	
	.menu-table {
		
		.shrink {
			padding: .3rem 3rem;
		}
		
	}
	
	.menu-header {
		padding: 0px;
	}
	
	.menu-table-nav {
		
		padding: 0px;
		margin-right: 0rem;
		margin-left: 0rem;
		width: 100%;
		
		& > .link {
			margin-bottom: 0rem;
		}
		
	}
	
}